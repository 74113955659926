<template>
    <div class="download-main">
        <div class="main-sect">
            <div class="sect-banner">
                <div class="btn-bottom" @click="downloadFun">
                    <span class="txt">{{$t("download.btntxt")}}</span>
                    <i class="icon"></i>
                </div>
            </div>
        </div>
        <div class="download-tips" v-show="showshare" @click="showshare= false">
            <p class="txt">请点击右上角选择“浏览器中打开”</p>
        </div>
    </div>
</template>

<script>
import { appVersionGet } from "@/api/index";
import wx from 'weixin-jsapi'
import wxConfig  from "@/utils/wxconfig"
import VConsole from 'vconsole/dist/vconsole.min.js'

export default {
    data () {
        return {
            showshare:false,
            appUrl:""
        }
    },
    async created () {
        // new VConsole()
        if(window.navigator.userAgent.indexOf('micromessenger')!==-1){
            await wxConfig.init();
            
            this.showshare = true;
        }
        
        this.getAppUrl();
        document.title = "速康宝";
    },
    methods: {
        getAppUrl(){
            appVersionGet().then(res=>{
                console.log(res);
                this.appUrl = res.data.data.dlUrl;
            })
        },
        downloadFun(){
            var ua = window.navigator.userAgent.toLowerCase(); 
            if(ua.match(/MicroMessenger/i) == 'micromessenger'){
                if(window.navigator.userAgent.indexOf('micromessenger')!==-1){
                    wxConfig.showMenu()
                }
                this.showshare = true;
            }else{
                // console.log('嗯嗯');
                window.location.href = this.appUrl;
            }
        }
    }
}   
</script>

<style lang="scss" scoped>
.download-main{
    height: 100vh;
    background-size: 100% 100%;
    background-image:url("~@/assets/img/download-back.png");
    background-repeat: no-repeat;
    .main-sect{
        background: url("~@/assets/img/download-banner1.png") no-repeat;
        height: 100%;
        background-size: auto 80vh;
        background-position-y: 7vh;
        background-position-x: right;
        .sect-banner{
            background: url("~@/assets/img/download-banner2.png") no-repeat;
            height: 100%;
            background-size: 84vw auto;
            background-position-y: 16vh;
            background-position-x: center;
            position: relative;
            .btn-bottom{
                position: absolute;
                bottom: 8vh;
                width: 72.8vw;
                left: 13.6vw;
                height: 8vh;
                border-radius: 4vh;
                background: url("~@/assets/img/download-btn-back.png") no-repeat;
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;                
                .txt{
                    font-size: 0.82rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    // line-height: 8vh;
                    color: #FEFFFF;
                }
                .icon{
                    width: 0.76rem;
                    height: 0.72rem;
                    background: url("~@/assets/img/download-btn-icon.png") no-repeat;
                    background-size: 100% 100%;
                    margin-left: 0.4rem;
                }
            }
        }

    }
    .download-tips{
        position: fixed;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        top: 0 ;
        left: 0;
        .txt{
            float: right;
            margin-right: 0.6rem;
            margin-top: 0.4rem;
            width: 47vw;
            height: auto;
            box-sizing: border-box;
    
            border-image: linear-gradient(0deg, #C2E8FD, #FFFFFF) 10 10;
            background: url("~@/assets/img/download-tips.png") no-repeat;
            background-size: 100% 100%;
            border-radius: 12px;
            padding: 0.96rem 0.88rem 0.6rem 0.88rem;
            font-size: 0.64rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            border: 0;

        }
    }
}
</style>